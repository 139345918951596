<template>
    <div style="height:100vh">
        <iframe width="100%" height="100%" :src="textFileUrl" frameborder="0"></iframe>
    </div>
</template>
<script>
import {getFileViewUrl} from '@/api/index'
export default {
    data(){
        return{
            textFileUrl:''
        }
    },
    computed: {
        fileId(){
            return this.$route.query.fileId
        },
    },
    mounted(){
        if(this.fileId!==''){
            getFileViewUrl({fileId: this.fileId}).then((res)=>{
                if(res.code==200){
                    this.textFileUrl = res.data.viewUrl;
                }
            })
        }
    }
}
</script>
